import React, {useState, useEffect} from "react"
import Config from "../../utility/Config";
import { useLoginSession } from "../../stores/loginSession";
import { useBackend } from "../../utility/Backend";
import Backend from "../../utility/Backend";
import ExportProgress from "../../components/ExportProgress";
import CustomModal from "../../components/CustomModal";
import ConfirmModal from "../../components/ConfirmModal";
import { capitalizeFirstLetter, getDateFormat, getDisplayDuration, getSizeFormat } from "../../utility/Utilities";
import { useCheckIsAdmin } from "../../utility/UserGroups";
import { useMutateByRegex } from "../../utility/Utilities";
import classNames from "classnames";
import "./Exports.css"
import {BiSort} from "react-icons/bi";
import {BsInfoCircle} from "react-icons/bs";
import {FaTrashAlt} from "react-icons/fa";

function Exports ({isOpen, onClose}) {

    const {token} = useLoginSession()
    const [isFsAdmin,] = useCheckIsAdmin()
    const mutateByRegex = useMutateByRegex()

    const [downloadIndex, setDownloadIndex] = useState([])
    const [sortBy, setSortBy] = useState("submitted ascending")
    const [exportToBeRemoved, setExportToBeRemoved] = useState(null)
    const [fetchExportMoreFrequent, setFetchExportMoreFrequent] = useState(false)

    // this useEffect is for disabling the button for 5 secs in case the user clicks download 
    // and the status does not change to "processing straight away"
    useEffect(() => {
        const removeIsDownloading = setTimeout(() => {
            setDownloadIndex([]);
        }, 5000)
        return () => clearTimeout(removeIsDownloading)
    }, [downloadIndex])

    const apiQuery = {access_token: token, count: 25}
    const fetchInterval = {
        // 5 secs when there's ongoing export, 20 secs when none
        dedupingInterval: fetchExportMoreFrequent ? 4900 : 19900,
        refreshInterval: fetchExportMoreFrequent ? 5000 : 20000,
    }

    const {data} = useBackend("/video_export", apiQuery, fetchInterval)

    let exports = data?.video_exports || []
    
    const hasRunningExport = exports.some((e) => !e.download_link)
    useEffect(() => {
        setFetchExportMoreFrequent(hasRunningExport)
    }, [hasRunningExport])

    if (!data) return null

    const onDownload = (event, mp4url, description, index) => {
        if (event) event.stopPropagation()
        setDownloadIndex([...downloadIndex, index]);

        // AdobePPro
        if (Config.premiereProPlugin) {
            event.preventDefault();

            // set name to the frist 40 characters of the description if set, otherwise
            // generate a random string of 10 characters as name of the export
            let name = description ? description.substring(0, 40).replace(/\s/g, "_") : Math.random().toString(36).substring(2, 15);

            const date = new Date();
            const dateString = date.getFullYear().toString().slice(-2) + "" +
                                (date.getMonth() + 1).toString().padStart(2, "0") + "" +
                                date.getDate().toString().padStart(2, "0") + "T" +
                                date.getHours().toString().padStart(2, "0") + "" +
                                date.getMinutes().toString().padStart(2, "0") + "_";

            window.top.postMessage({
                type: "ppro",
                mp4url: mp4url,
                token: "XXX", // Note: We don't need to get a token since the exports are public
                name: dateString + name
            }, "*");
            return;
        }
    }

    const removeExport = () => {
        const query = {access_token: token}
        Backend.delete("/video_export/" + exportToBeRemoved?.id, query)
            .then(({error}) => {
                if (error) {
                    console.error("Failed to DELETE", error)
                } else {
                    console.log("export removed")
                    mutateByRegex(/^\/video_export/)
                }
            });
    }

    const sortSubmitted = () => {
        if (sortBy !== "submitted ascending") setSortBy("submitted ascending")
        else setSortBy("submitted descending")
    }

    const sortExpires = () => {
        if (sortBy !== "expires ascending") setSortBy("expires ascending")
        else setSortBy("expires descending")
    }

    const sortSize = () => {
        if (sortBy !== "size ascending") setSortBy("size ascending")
        else setSortBy("size descending")
    }

    const sortDuration = () => {
        if (sortBy !== "duration ascending") setSortBy("duration ascending")
        else setSortBy("duration descending")
    }

    switch (sortBy) {
        case "submitted ascending":
            exports.sort((a,b) =>  new Date(b.submitted) - new Date(a.submitted))
            break;
        case "submitted descending":
            exports.sort((a,b) => new Date(a.submitted) - new Date(b.submitted)) 
            break;
        case "expires ascending":
            exports.sort((a,b) =>  new Date(b.expires) - new Date(a.expires))
            break;
        case "expires descending":
            exports.sort((a,b) => new Date(a.expires) - new Date(b.expires)) 
            break;
        case "size ascending":
            exports.sort((a,b) =>  new Date(b.size_bytes) - new Date(a.size_bytes))
            break;
        case "size descending":
            exports.sort((a,b) => new Date(a.size_bytes) - new Date(b.size_bytes)) 
            break;
        case "duration ascending":
            exports.sort((a,b) =>  new Date(b.duration_s) - new Date(a.duration_s))
            break;
        case "duration descending":
            exports.sort((a,b) => new Date(a.duration_s) - new Date(b.duration_s)) 
            break;
        default:
            break;
    }

    const removeExportModal = (
        <ConfirmModal 
            isOpen 
            onClose={() => setExportToBeRemoved(null)} 
            onConfirm={removeExport} 
            confirmText = "Remove"
            >
            <div className="confirm-icon-message">
                <div className="confirm-icon"><FaTrashAlt/></div>
                <div className="confirm-title">Do you really want to remove {exportToBeRemoved?.description} from export?</div>
            </div>
        </ConfirmModal>
    );

    const exportsTable = (
        <div className="exports-table scrollable">
            <div className="table-header-cont sticky">
                <div onClick={sortSubmitted} className="export-submitted table-header-sort">
                    Submitted
                    <BiSort className="table-header-sort-icon"/>
                </div>
                <div className="export-source">Source</div>
                <div className="export-preset">Preset</div>
                <div className="export-creator">Creator</div>
                <div onClick={sortExpires} className="export-expires table-header-sort">
                    Expires
                    <BiSort className="table-header-sort-icon"/>
                </div>
                <div onClick={sortSize} className="export-size table-header-sort">
                    Size
                    <BiSort className="table-header-sort-icon"/>
                </div>
                <div onClick={sortDuration} className="export-duration table-header-sort">
                    Duration
                    <BiSort className="table-header-sort-icon"/>
                </div> 
                <div className="export-download">Download</div>
                {isFsAdmin && <div className="export-delete">Del</div>}
            </div>
            {exports.map((e, idx) => {
                const isDownloadActive = new Date(e.expires) > new Date()
                return (
                    <div key={e.id} className={classNames("export-clip-single", {"expired": !isDownloadActive})}>
                        <div className="export-submitted">{getDateFormat(e.submitted, true, true)}</div>
                        <div className="export-source data">{e.description}</div>
                        <div className="export-preset">{e.output_preset? capitalizeFirstLetter(e.output_preset.replace("_", " ")) : "-"}</div>
                        <div className="export-creator">{e.creator.name}</div>
                        {e.download_link? (
                            <>
                                <div className="export-expires">{getDateFormat(e.expires, true, true)}</div>
                                <div className="export-size data">{e.size_bytes? getSizeFormat(e.size_bytes) : "5.5 MB"}</div>
                                <div className="export-duration data">{e.duration_s > 0? getDisplayDuration(e.duration_s * 1000) : "-"}</div> 
                                <div className="export-download">
                                    {isDownloadActive? (
                                        <a href={e.download_link} onClick={(event) => onDownload(event, e.download_link, e.description, idx)}>
                                            <button disabled={downloadIndex.includes(idx)} className="smaller-font-btn">
                                                Download
                                            </button>
                                        </a>
                                    ) : (<div className="download-expired">Expired</div>)}
                                </div>
                            </>
                            ) : (
                                <div className="export-processing">
                                    <ExportProgress percentage={e.progress} status={e.status}/>
                                </div>
                            )}
                        {isFsAdmin && (
                            <div className="export-delete icon">
                                <FaTrashAlt onClick={() => setExportToBeRemoved({id: e.id, description: e.description})} className="remove-export-btn"/>
                            </div>
                        )}
                    </div>
                )
            })}
        </div>
    )
    
    return (
        <CustomModal isOpen={isOpen} onRequestClose={onClose} className="wider">
            <div className="exports-cont">
                <div className="exports-modal-title">Exports</div>
                {exports.length > 0? 
                    exportsTable : (
                        <div className="no-exports-table">No exports to show</div>
                    )}
                <div className="confirm-cancel-btn-cont center">
                    <button type="button" onClick={onClose}>Close</button>
                    <div className="exports-table-info">
                        <BsInfoCircle/>
                        <i> This table lists your queued and past exports.
                            You may close and check back later at any point. 
                            <br/> Download link will be valid for a couple of weeks.
                        </i>
                    </div>
                </div>
            </div>
            {exportToBeRemoved && removeExportModal}
        </CustomModal>
    )
}

export default Exports