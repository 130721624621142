import React, {useEffect, useState} from "react"
import { useCompilation } from "../stores/compilation";
import { useLoginSession } from "../stores/loginSession";
import Backend from "../utility/Backend";
import { useParams } from "react-router-dom";
import { useFeedbackMessage } from "../stores/FeedbackMessage";
import { useUpdateSearchParams } from "../utility/Utilities";
import ConfirmModal from "./ConfirmModal";
import CustomModal from "./CustomModal";
import classNames from "classnames";
import { showErrorMessage } from "../utility/Utilities";
import {MdListAlt} from "react-icons/md";
import {CgUnavailable} from "react-icons/cg";
import {BsInfoCircle} from "react-icons/bs";
import "./css/GenerateHighlights.css";

function GenerateHighlights ({game}) {

    const [highlightsType, setHighlightsType] = useState("headtohead")
    const [warningModal, setWarningModal] = useState(null)
    const [highlightsVideo, setHighlightsVideo] = useState(null)
    const [isPrivate, setIsPrivate] = useState(true)
    const [highlightsError, setHighlightsError] = useState(null)

    const {showFeedback} = useFeedbackMessage();
    const {initializeCompilation} = useCompilation();
    const [, updateSearchParams] = useUpdateSearchParams();
    const {compilationExists, playlistId: compilationId} = useCompilation();

    const {token} = useLoginSession()
    const query = {access_token: token}
    const {gameId} = useParams()
    
    const initGenerateHighlights = async (type) => {

        let pregameEndpoint
        if (type === "headtohead") {
            pregameEndpoint = `/suggest/game/${gameId}/pregame/headtohead/v1`
        } else {
            pregameEndpoint = `/suggest/game/${gameId}/pregame/team/${type}/v1`
        }

        const {data, error} = await Backend.post(pregameEndpoint, query)
        if (error) {
            setHighlightsError(error)
        }
        else {
            setHighlightsVideo(data)
            resetGenerateHighlights()
        }
    }
    
    useEffect(() => {
        if (!highlightsVideo) return
        else {
            initializeCompilation(highlightsVideo, highlightsVideo.id, true);
            updateSearchParams({"editing": "playlist_" + highlightsVideo.id});
            showFeedback("success", "New compilation created successfully!");
            setHighlightsVideo(null)
        }
    }, [highlightsVideo])

    useEffect(() => {
        if (highlightsError) setHighlightsError(false)
    }, [highlightsType])

    const toExistingVideoEdit = () => {
        updateSearchParams("editing", "playlist_" + compilationId);
        setWarningModal(null)
    }

    const generateHighlightsConfirmation = () => {
        if (compilationExists) setWarningModal("compilation exists")
        else setWarningModal("generate highlights")
    }

    const resetGenerateHighlights = () => {
        setHighlightsType("headtohead")
        setWarningModal(null)
        setIsPrivate(true)
        setHighlightsError(null)
    }

    const handleGenerateHighlights = (type) => {
        initGenerateHighlights(type)
    }

    const {home_team, visiting_team, date} = game

    const generateHighlightsModal = (
        <CustomModal isOpen onRequestClose={resetGenerateHighlights} className="small-tall">
            <div className="generate-highlights-cont">
                <div className="generate-highlights-title">Generate pre-game highlights</div>
                <div className="generate-highlights-info">
                    <div className="generate-highlights-game">
                        <div>{home_team.short_name}</div>
                        <img src={home_team.logo_url} alt="" />
                        <div className="pre-game-highlights-date">{date}</div>
                        <img src={visiting_team.logo_url} alt="" />
                        <div>{visiting_team.short_name}</div>
                    </div>
                </div>
                <div className="generate-highlights-type-cont">
                    <div onClick={() => setHighlightsType("headtohead")} className={classNames("generate-highlights-type", {"active": highlightsType === "headtohead"})}>
                        <div className="generate-highlights-team">Head-to-Head</div>
                        <div className="option-extra-message"><BsInfoCircle className="info-icon"/> Highlights from last 2 head-to-head games</div>
                    </div>
                    <div onClick={() => setHighlightsType(home_team.id)} className={classNames("generate-highlights-type", {"active": highlightsType === home_team.id})}>
                        <div className="generate-highlights-team">
                            <img src={home_team.logo_url} alt="" />
                            <div className="generate-highlights-team-name">{home_team.name}</div>
                        </div>
                        <div className="option-extra-message"><BsInfoCircle className="info-icon"/> Highlights from last 2 games of {home_team.name}</div>
                    </div>
                    <div onClick={() => setHighlightsType(visiting_team.id)} className={classNames("generate-highlights-type", {"active": highlightsType === visiting_team.id})}>
                        <div className="generate-highlights-team">
                            <img src={visiting_team.logo_url} alt="" />
                            <div className="generate-highlights-team-name">{visiting_team.name}</div>
                        </div>
                        <div className="option-extra-message"><BsInfoCircle className="info-icon"/> Highlights from last 2 games of {visiting_team.name}</div>
                    </div>
                    {showErrorMessage(highlightsError, highlightsError)}
                    {/* <div className="generate-highlights-publish">
                        <div className="input-container-toggle">
                            <label className="toggle-title">Publish</label>
                            <div onClick={() => setIsPrivate(!isPrivate)} className={classNames("toggle-switch", {"active": !isPrivate})}>
                                <div className="toggle-button"></div>
                            </div>
                        </div>
                        <div className="option-extra-message"><BsInfoCircle className="info-icon"/> Publish to Highlights page</div>
                    </div> */}
                </div>
                <div className="confirm-cancel-btn-cont full">
                    <button className="green-hover-btn" onClick={() => handleGenerateHighlights(highlightsType)}>
                        Generate
                    </button>
                    <button onClick={resetGenerateHighlights}>
                        Cancel
                    </button>
                </div>
            </div>
        </CustomModal>
    )

    const compilationExistsModal = (
        <ConfirmModal 
            isOpen
            onClose={() => setWarningModal(null)}
            cancelText="Close"
            noConfirmBtn
            >
            <div className="confirm-icon-message">
                <div className="confirm-icon"><MdListAlt/></div>
                <div className="confirm-title">You have existing new compilation or highlights. Save or delete the existing to create a new one.</div>
                <span onClick={toExistingVideoEdit} className="to-existing-video-edit">Go to existing compilation / highlights</span>
            </div>
        </ConfirmModal>
    );

    const highlightsNotAvailableModal = (
        <ConfirmModal 
            isOpen
            onClose={() => setWarningModal(null)}
            cancelText="Close"
            noConfirmBtn
            >
            <div className="confirm-icon-message">
                <div className="confirm-icon"><CgUnavailable/></div>
                <div className="confirm-title">Match is not started or has no events yet</div>
            </div>
        </ConfirmModal>
    );

    return (
        <>
            <button type="button" className="generate-highlights-btn green-hover-btn" onClick={generateHighlightsConfirmation}>
                Generate pre-game highlights
            </button>
            {warningModal === "generate highlights" && generateHighlightsModal}
            {warningModal === "compilation exists" && compilationExistsModal}
            {warningModal === "unavailable" && highlightsNotAvailableModal}
        </>
    )
}

export default GenerateHighlights